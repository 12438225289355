<template>
  <div>
    <div class="position-relative overflow-hidden">
      <div class="mid-controll-container" align="right">
        
        <div>
          <b-button @click="lockMovementX = !lockMovementX" variant="none">
            <span class="text-dark mr-1">Disable horizontal drag</span>
            <b-icon icon="circle" v-if="!lockMovementX" variant="success"/>        
            <b-icon icon="record-circle" v-else variant="success"/>        
          </b-button>
        </div>
        <div>
          <b-button @click="toggleShow('previous')" variant="none">
            <span class="text-dark mr-1">Show <b class="text-favorite">PREVIOUS</b> Arrow</span>
            <b-icon icon="circle" v-if="!showPrevious" variant="success"/>        
            <b-icon icon="record-circle" v-else variant="success"/>        
          </b-button>
        </div>
        <div>
          <b-button @click="toggleShow('next')" variant="none">
            <span class="text-dark mr-1">Show <b class="text-success">NEXT</b> Arrow</span>
            <b-icon icon="circle" v-if="!showNext" variant="success"/>        
            <b-icon icon="record-circle" v-else variant="success"/>        
          </b-button>
        </div>
        <div>
          <b-button @click="toggleShow('parent')" variant="none">
            <span class="text-dark mr-1">Show <b class="text-light">PARENT</b> line</span>
            <b-icon icon="circle" v-if="!showParent" variant="success"/>        
            <b-icon icon="record-circle" v-else variant="success"/>        
          </b-button>
        </div>

      </div>
      <template v-if="Array.isArray(orderedMiddlewares)">  
        <div ref="middlewares-container" class="middlewares-container" :style="`height: ${(orderedMiddlewares.length * 100) + 100}px;`">
          <div 
            v-for="(middleware, mIdx) in orderedMiddlewares" 
            :key="`middleware-block-${mIdx}-${uuid}-${middleware.id}`" 
            class="middleware-block"
            :style="`
            left: ${150 + (getMiddlewareDepth(middleware.id) * 50)}px;
            top: ${50 + (mIdx * 70)}px;
            `"
            :data-middleware-id="middleware.id"
            tabindex="0"
            >
            <span>{{ middleware.id }}</span>
          </div> 
        </div>
        
        <svg ref="d3-svg" class="svg-element" :style="`height: ${(orderedMiddlewares.length * 100) + 100}px;`">
        
        </svg>
      </template>
    </div>
  </div>
</template>

<script>
import { 
  BButton,
  BFormRadio,
  BFormGroup,
 } from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import * as d3 from "d3";

  export default {
    components: {
      BButton,
      BFormRadio,
      BFormGroup,
    },
    data() {
      return {
        orderedMiddlewares: undefined,
        uuid: uuidv4(),
        dragWasMounted: false,
        lockMovementX: true,
        showNext: true,
        showPrevious: true,
        showParent: true,
      }
    },
    props: {
      middlewares: {
        type: Array,
        required: true
      },
    },
    computed: {
      treatedMiddlewares() {
        if (!Array.isArray(this.middlewares)){
          return
        }
        return this.middlewares.map(el =>{ return{
          id: el.id,
          parent: el.mid_parent,
          next: el.mid_next,
          prev: el.mid_previous,
        }}) 
      }
    },
    mounted () {
      
      let first = []
      let middle = []
      let last = []

      
      this.treatedMiddlewares.forEach((mid)=>{
        if (mid.prev == null){
          first.push(mid)
        } else if (mid.next == null){
          last.push(mid)
        } else {
          middle.push(mid)
        }
      })

      // sorting Middle

      let orderedMiddle = []
      try {
        let midsPreSort = structuredClone(middle)
        let r = [ midsPreSort.find(el => el.id == first[0].next) ]

        while (r[r.length - 1]?.next != null && r.length < midsPreSort.length){
          const n = midsPreSort.find(el => el.id == r[r.length-1].next) 
          if (n){
            r.push(n)
          }
        }
        
        r.forEach((el)=>{
          orderedMiddle.push(el)
        })
        midsPreSort.forEach((mid)=>{
          if (orderedMiddle.findIndex(e => e.id == mid.d) == -1 ){
            orderedMiddle.push(mid)
          }
        })
      } catch(err){
        console.error(err)
        orderedMiddle = structuredClone(middle)
      }
      const all = [ ...first , ...orderedMiddle , ...last ]

      let r = []
      all.forEach((m)=>{
        //removing repeated elements
        if (m != null && r.findIndex(e => e.id == m.id) == -1){
          r.push(m)
        }
      })
      this.orderedMiddlewares = r

      this.$nextTick(()=>{
        this.makeArrows()
      })
    },
    methods: {
      getMiddlewareDepth(id) {
        const mid = this.treatedMiddlewares.find(el => el.id == id)
        
        if (mid.parent == null){
          return 0
        }
        return this.getMiddlewareDepth(mid.parent) + 1
      },
      makeArrows(){
        const middlewaresContainer = this.$refs['middlewares-container']
        const svg = d3.select(this.$refs['d3-svg']);
        const blockSize = 50;


        function getCoords(id){
          const el = [...middlewaresContainer.children].find(el => String(el.dataset.middlewareId) == String(id))
          return {
            y: el.offsetTop,
            x: el.offsetLeft
          }
        }
        svg.selectAll("path").remove()
        // <marker
        //   id="parent-base"
        //   viewBox="0 0 10 10"
        //   refX="5"
        //   refY="5"
        //   markerWidth="10"
        //   markerHeight="10"
        //   fill="#ffffff50"
        //   orient="auto-start-reverse">
        //   <circle cx="5" cy="5" r="3"/>
        // </marker>
        svg.node().innerHTML = `<defs>
          <!-- A marker to be used as an arrowhead -->
          <marker
            id="arrow-green"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            stroke="#28c76f"
            fill="#28c76f"
            orient="auto-start-reverse">
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>

          <marker
            id="arrow-yellow"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            stroke="#cf9c29"
            fill="#cf9c29"
            orient="auto-start-reverse">
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>



          <marker
            id="parent-base"
            viewBox="0 0 5 5"
            refX="2.5"
            refY="0"
            markerWidth="5"
            markerHeight="5"
            storkeWidth="0"
            fill="#FFFFFF"
            orient="auto-start">
              <path d="M0,0 a2.5,2.5 0 0,0 5,0"/>
          </marker>

        </defs>`
        for (const midEl of middlewaresContainer.children) {

            if (!this.dragWasMounted){
              this.mountDrag(midEl)
            }

          const mid = this.treatedMiddlewares.find(el => String(el.id) == String(midEl.dataset.middlewareId))

          const drawArrows = (mid, isNext)=>{
            const coordsA = getCoords(mid.id)
            const coordsB = isNext ? getCoords(mid.next) : getCoords(mid.prev)

            
            const yDif = (coordsA.y + blockSize/3 * 2) - (coordsB.y +blockSize/3)

            const minCoordX = Math.min(coordsB.x , coordsA.x)
            const maxCoordX = Math.max(coordsB.x + blockSize, coordsA.x + blockSize)
            
            let curve_path
            if (isNext){

              curve_path = `
                M ${coordsA.x + (blockSize)} ${coordsA.y + blockSize/3 * 2} 
                  C 
                  ${maxCoordX + (blockSize/2) + Math.abs(yDif)} ${coordsA.y + blockSize/3 * 2} ,
                  ${maxCoordX + (blockSize/2) + Math.abs(yDif)} ${coordsB.y +blockSize/3}  ,
                  ${coordsB.x + blockSize} ${coordsB.y +blockSize/3}  
              `;
              svg.append("path")
                .attr("d",curve_path)
                .attr("stroke",'#28c76f')  
                .attr("stroke-width",'2.5')  
                .attr("fill","none")  
                .attr("marker-end","url(#arrow-green)")  
            }  else {
              
              curve_path = `
                M ${coordsA.x} ${coordsA.y + blockSize/3} 
  
                  C 
                  ${minCoordX - (Math.abs(yDif))} ${coordsA.y + blockSize/3}  ,
                  ${minCoordX - (Math.abs(yDif))} ${coordsB.y + (blockSize/3*2)}   ,
                  ${coordsB.x} ${coordsB.y + (blockSize/3 * 2)}  
              `;
              
              svg.append("path")
                .attr("d",curve_path)
                .attr("stroke",'#cf9c29')  
                .attr("stroke-width",'2.5')  
                .attr("fill","none")  
                .attr("marker-end","url(#arrow-yellow)")  

            }
          }
          
          if (mid.next != null && this.showNext){
            drawArrows(mid, true)
          }


          if (mid.prev != null && this.showPrevious){
            drawArrows(mid, false) 
          }


          if (mid.parent != null && this.showParent){
            let coordsA = getCoords(mid.id)
            coordsA.y = coordsA.y + (blockSize/2)

            let coordsB = getCoords(mid.parent)
            coordsB.x = coordsB.x + (blockSize/2)
            coordsB.y = coordsB.y + blockSize

            let path = `
              M ${coordsA.x} ${coordsA.y} 
              L ${coordsB.x} ${coordsA.y} 
              L ${coordsB.x} ${coordsB.y}
            `
            svg.append("path")
              .attr("d",path)
              .attr("stroke",'#FFFFFF')  
              .attr("stroke-width",'3')
              .attr("stroke-dasharray", '5, 5')
              .attr("fill","none")
                .attr("marker-end","url(#parent-base)")  
            
          }


          
          // console.log('---------')
        }
        this.dragWasMounted = true
      },
      mountDrag(midEl){
        const makeArrows = ()=>{
          this.makeArrows()
        }

        let _this = this
        

        const selection = d3.select(midEl) 
          selection.call(d3.drag()
          
          .on("drag", function (d) {
            let x = d3.select(this).style("left")
            x = parseInt(x.replaceAll('px', '')) + d.dx

            let y = d3.select(this).style("top")
            y = parseInt(y.replaceAll('px', '')) + d.dy

            d3.select(this)
              .style("top",`${y}px`);

            if (! (_this.lockMovementX)){
              d3.select(this)
                .style("left",`${x}px`);
            }
            
              makeArrows()
            })

          )
      },
      toggleShow(field){
        switch (field) {
          case 'previous':
            this.showPrevious = !this.showPrevious
            break;
          case 'next':
            this.showNext = !this.showNext
            break;
          case 'parent':
            this.showParent = !this.showParent
            break
        }
        this.makeArrows()
      }
    },
  }
</script>


<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.middlewares-container{
  position: relative;
  background-color: #0f1422;
  border-radius: 10px;
  border: 1px solid transparentize($light, 0.9);
  padding: 20px;
}

.middleware-block{    background-color: lighten($card-blue, 10);

  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  outline: 1px solid $black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  cursor: move;
  transition: background 0.25s;
  span{ 
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-size: 18px;
  }
  &:hover{
    background-color: lighten($card-blue, 20);
  }
  &:active{
    outline: 1px solid transparentize($info, 0.1) !important;
    background-color: lighten($card-blue, 20);
  }
}

.svg-element{
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.mid-controll-container{
  width: fit-content;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.FFFFFF{
  color: #ffffff70;
}
</style>