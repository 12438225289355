<template>
  <div>
    

    <template v-if="loaded">
       
      <div v-for="(middlewares, idx) in midsPerCategory" :key="'mids-per-category-'+idx" class="mb-5">
        <div class="mb-25 font-weight-bolder d-flex">
          <div class="mr-3">
            Service <b-icon icon="arrow-right-short" variant="success"/> {{ getServiceLabel(middlewares[0].ser_id) }}
          </div>
          <div > 
            Event <b-icon icon="arrow-right-short" variant="success"/> {{ getEventLabel(middlewares[0].eve_id) }}
          </div>
        </div>
        
        <div>  
          <view-middleware-nodes :middlewares="middlewares"/>
        </div>

      </div>
      
    </template>
    <div class="m-5 p-5" align="center" v-else>
      <span style="transform: scale(1.5)" class="d-block">
        <b-spinner/>
      </span>
    </div>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>


    {{ midsPerCategory }}
  </div>
</template>

<script>
import {BSpinner} from 'bootstrap-vue'
import ViewMiddlewareNodes from '@/layouts/components/Transmission/Middleware/ViewMiddlewareNodes.vue'
  export default {
    components: {
      ViewMiddlewareNodes,
      BSpinner
    },
    data() {
      return {
        midsPerCategory: undefined,
        events: undefined,
        services: undefined,
      }
    },
    computed: {
      transmissionID(){
        return this.$route.params.transmissionID
      },
      loaded(){
        return Array.isArray(this.midsPerCategory) && Array.isArray(this.events) && Array.isArray(this.services)
      }
    },
    mounted () {
      this.$store.dispatch('getMiddlewareNodes',this.transmissionID)
        .then((resp)=>{
          this.midsPerCategory = this.makeMidsPerCategory(resp)          
        })

      this.$store.dispatch('fetchEventsByTransmissionGroup',{transmissionID: this.transmissionID})
        .then((resp)=>{
          this.events = resp
        })
        
        this.$store.dispatch('getServicesByTransmissionGroup',{transmissionID: this.transmissionID})
        .then((resp)=>{
          this.services = resp.items
          // console.log('%c'+JSON.stringify(resp), 'color: limegreen');
        })
    },
    methods: {
      makeMidsPerCategory(mids) {
        let r = []

        mids.forEach((m)=>{
          let idx = r.findIndex((el)=>( el[0].ser_id == m.ser_id) && (el[0].eve_id == m.eve_id) )
          if (idx == -1){
            idx = r.length
            r[idx] = []
          }
          r[idx].push(m)
        })

        return r
      },
      getEventLabel(id){
        if (id != null){
          return this.getEventById(id).event_identifier
        } else {
          return "none"
        }
      },
      getEventById(id){
        return this.events.find(el => el.id == id)
      },
      getServiceLabel(id){
        if (id != null){
          return this.getServiceById(id).identifier
        } else {
          return 'none'
        }
      },
      getServiceById(id){
        return this.services.find(el => el.id == id)
      }
      
    },
  }
</script>

<style lang="scss" scoped>

</style>